<template>
  <div class="container-fluid p-0 home-bg">
    <div class="content-container">

            <div class="content">
                <h1>Aplicaciones desplegadas online (WebGL)</h1>
                <ul>
                  <li v-for="(link, index_link) in content.links" v-bind:key="index_link"><a :href="link.url" target=”_blank” class="general-link">{{link.name}}</a></li>
                    <!-- <li><a href ="https://oommur-pluto.ctnaval.com" target=”_blank” class="general-link">OOMMUR - Training Pluto</a></li>
                    <li><a href ="https://modem.ctnaval.com" target=”_blank” class="general-link">MODEM</a></li>
                    <li><a href ="https://pecio-mazarron.ctnaval.com" target=”_blank” class="general-link">Pecio Mazarrón II (Sólo dispositivo móvil)</a></li>
                    <li><a href ="https://smartlean.ctnaval.com" target=”_blank” class="general-link">SmartLean - Posicionador</a></li>
                    <li><a href ="https://mardigital-viewer.ctnaval.com/" target=”_blank” class="general-link">Mar Digital - Fuerte de Navidad</a></li> -->
                </ul>

                <div class="separator"></div>

                <h1>APK's para instalación nativa en Android</h1>
                <ul>
                  <li v-for="(apk, apk_link) in content.apks" v-bind:key="apk_link"><a :href="apk.url" target=”_blank” class="general-link">{{apk.name}}</a></li>
                    <!-- <li><a href ="./resources/apk/metamateriales_visualizer.apk" target=”_blank” class="general-link">Visor Metamateriales</a></li>
                    <li><a href ="./resources/apk/DSC_Android.apk" target=”_blank” class="general-link">DSC - Piscifactorías</a></li>
                    <li><a href ="./resources/apk/pos.apk" target=”_blank” class="general-link">Posicionador</a></li> -->
                </ul>

                <div class="separator"></div>

                <h1>Builds para ejecutar en PC</h1>
                <ul>
                  <li v-for="(build, build_link) in content.builds" v-bind:key="build_link"><a :href="build.url" target=”_blank” class="general-link">{{build.name}}</a></li>
                    <!-- <li><a href ="./resources/builds_pc/Acuicultura_build_PC_V2023-01-17.rar" target=”_blank” class="general-link">DSC - Piscifactorías</a></li>
                    <li><a href ="./resources/builds_pc/BUILD_Positioner_Materiales.rar" target=”_blank” class="general-link">Posicionador con metamateriales</a></li>
                    <li><a href ="./resources/builds_pc/sr_puertos_build_windows.zip" target=”_blank” class="general-link">MODEM - Puertos</a></li> -->
                </ul>

                <div class="separator"></div>

                <h1>Vídeos</h1>
                <ul>
                  <li v-for="(video, video_link) in content.videos" v-bind:key="video_link"><a :href="video.url" target=”_blank” class="general-link">{{video.name}}</a></li>
                    <!-- <li><a href ="./resources/videos/DSC_V2.mp4" target=”_blank” class="general-link">DSC - Piscifactorías</a></li>
                    <li><a href ="./resources/videos/Posicionador.mp4" target=”_blank” class="general-link">Posicionador</a></li> -->
                </ul>
            </div>            
        </div>
  </div>
</template>

<script>

// import vtpanel from '@/components/panel'
// import papersList from '@/components/papersList'
import Content from "@/assets/content.json"

export default {
  name: 'Home',
  // components: {
  //   vtpanel,
  //   papersList
  // },
  computed: {
  },
  data() {
    return {
      content: {}
    }
  },
  created () {
   
  },
  mounted () {
    this.content=Content
    console.log(this.content)
  },
  watch: {
  },
  methods: {
 
  }
}
</script>
<style scoped>
</style>