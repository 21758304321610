<template>
  <footer>
    <div class="footer-content">
      2023 © Centro Tecnológico Naval y del Mar
    </div>
  </footer>
</template>

<script>
export default {
  name: 'listFooter',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>
  .footer-content{
    height: 100%;
    display: flex;
    align-items: center;
  }
</style>
