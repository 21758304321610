import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Gallery from '../views/Gallery.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
    // meta: {
    //   requiresAuth: true
    // }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
