<template>
  <div class="container-fluid p-0 home-bg">
    <div id="Gallery">
      <h1>Galería</h1>
      <div class="separator"></div>
      <div class="gallery-container">
        <img 
          class="gallery-item" v-for="(image, i) in images" :src="image.url" :key="i" @click="index = i"/>
      </div>
      <vue-gallery-slideshow 
        :images="images" :index="index" @close="index = null">
      </vue-gallery-slideshow>
    </div>
  </div>
</template>

<script>

import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'Gallery',
  components:{
    VueGallerySlideshow,
  },
  data() {
    return {
      images: [],
      index: null,
    }
  },
  created () {
   
  },
  mounted () {
    const images = require.context(
      '@/../public/resources/images/',
      true,
      /^.*\.png$/
    );
    const imagePath = images.keys();
    imagePath.forEach(path => {
      this.images.push({
        url: images(path),
        alt: 'description',
      });
    });
  },
  watch: {
  },
  methods: {
  }
}

</script>
<style lang= "scss" scoped>

.container-fluid{
  text-align: center;
  margin-top: 10px;
}

.gallery-item{
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px; 
  height: auto;
  cursor: pointer;
}

.gallery-container{
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 justify-items: center;
}

</style>
