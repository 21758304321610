<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <img :src="require('@/assets/images/logo_ctn_white.png')" alt="logo CTN" style="height: 25px; width: auto; margin-right: 12px;" />
        Showroom
        <!-- Right side of Navbar -->
        <div class="collapse navbar-collapse" id="navbarToggler">
          <div style="flex: 1;"></div>
          <div class="options">
            <b-nav-item v-for="(item, index) in buttons" :key="index" :class="{'right-item': true, 'active': activeButton === index}" @click="handleClick(item.route, index)">{{ item.label }}</b-nav-item>
          </div>
          <ul class="nav navbar-nav navbar-right ml-auto ">
            <li class="nav-item dropdown mr-5 nav-inicio">
              <a v-if="isLoggedIn" id="navbarDropdown" class=" dropdown-toggle link-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/> -->
                <p v-if="fullname != ''" class="d-inline-block nav-user m-0 link-1">{{fullname}}</p>
                <p v-else class="d-inline-block nav-user m-0 link-1">{{username}}</p>
              </a>
              <!-- User options pop up -->
              <div class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="navbarDropdown">
                <div class="popup-body">
                  <!-- <div class="line-separator"><hr /></div> -->
                  <div>
                    <div @click="logout" class="dropdown-item pointer">
                      &nbsp;Logout
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </nav>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: "listHeader",
  components: {
  },
  data() {
    return {
      buttons: [
        {label: 'Links', route:'/'},
        {label: 'Galería', route:'/gallery'},
    ],
      activeButton: null
    };
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      fullname: 'fullname',
      is_admin: 'is_admin'
    }) 
  },
  methods:{
    logout: function () {
      this.$store.dispatch('user/logout')
      .then(resp => { console.log(resp)})
      .catch(err => err)
    },
    handleClick(route, index){
      if(this.activeButton === index){
        this.activeButton = null;
      }else{
        this.activeButton = index;
        this.$router.push(route);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.nav-inicio {
    align-content: center;
    display: grid
}

.profile-picture{
    width: 25px;
    padding-right: 5px;
}

.dropdown-user {
  z-index: 1000000;
}

.options{
  height: 100%;
  display: flex;
  align-content: center;
  margin-right: 60px;
}

.right-item{
  margin-left: 20px;
  cursor: pointer;
  position: relative;
}

.right-item:hover{
  color: #ffe557;
}

.right-item.active{
  color: #ffe557;
}

</style>
