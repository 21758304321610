import { postData,getData } from "@/services/api";
import { stringArray } from "@/services/helper";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Vuelidate from "vuelidate";

Vue.use(VueToast, Vuelidate);

const state = () => ({
  papers_raw: [],
  marks_fechas: [],
  fechas_range: [],
  histo_fechas: [],
  marks_citas: [],
  citas_range: [],
  histo_citas: [],
  // variables de los INPUTS
  tableFilter:{
    title: '',
    abstract: '',
    cita: '',
    year: '',
    magazine:'',
    author:''
  },
  formSearch: {
    nsearchs: 5,
    limit:100,
    n_resultados: 30,
    query: ''
  },
  formFilter: {
    min_fecha: '',
    max_fecha:'',
    min_cita: '',
    max_cita: '',
    log_op: 'AND'
  },
});

const mutations = {
  set_tableFilter(state, data) {
    state.tableFilter[data[0]] = data[1];
  },
  set_formSearch(state, data) {
    state.formSearch[data[0]] = data[1];
  },
  set_formFilter(state, data) {
    state.formFilter[data[0]] = data[1];
  },
  set_papersSearch(state, data) {
    state.papers_raw = data.papers_raw;
    state.marks_fechas = data.marks_fechas;
    state.fechas_range = data.fechas_range;
    state.histo_fechas = data.histo_fechas;
    state.marks_citas = data.marks_citas;
    state.citas_range = data.citas_range;
    state.histo_citas = data.histo_citas;
    state.papers_raw = data.papers_raw;
  },
};

const actions = {
  change_tableFilter({ commit }, data) {
    commit("set_tableFilter", data);
  },
  change_formSearch({ commit }, data) {
    commit("set_formSearch", data);
  },
  change_formFilter({ commit }, data) {
    commit("set_formFilter", data);
  },

  async get_papers_search({ commit, dispatch, getters }) {
    // Set 'loading' status
    //dispatch("ui/change_current_interface", null, { root: true });

    // POST FORM DATA search
    
    var res = await postData('acquisition/search/', getters['formSearch'])
    
    if (res.status == "200") {  
      
      res.data.papers_raw.forEach(paper => {
        paper['authors'] = stringArray(paper['authors'],'name')
      });
      commit("set_papersSearch", res.data);
      return 'ok'
    } else if (res.status < "500") {
      Vue.$toast.open({
        message: "The semantic scholar API is not working correctly: " + res.data,
        type: "error",
        position: "top",
        pauseOnHover: true,
        duration: 6000,
      })
      return 'error'
    }else{
      Vue.$toast.open({
        message: "The API is out of order. If the error persists contact with maintenance.",
        type: "error",
        position: "top",
        pauseOnHover: true,
        duration: 6000,
      })
      return 'error'
    }
  }
};

const getters = {
  papers_raw: state => state.papers_raw,
  papers_raw_sort: (state) => (type) => {
    var papers = [...state.papers_raw]
    var max_fecha = state.formFilter.max_fecha || 3000
    var min_fecha = state.formFilter.min_fecha || 1500
    var max_cita = state.formFilter.max_cita || 10000000
    var min_cita = state.formFilter.min_cita || 0
    var log_op = state.formFilter.log_op

    papers = papers.filter(function(paper) {
      if (log_op == 'AND'){
        if ((paper.year >= min_fecha && paper.year <= max_fecha) && (paper.citationCount >= min_cita && paper.citationCount <= max_cita))
          return true
      }else {
        if ((paper.year >= min_fecha && paper.year <= max_fecha) || (paper.citationCount >= min_cita && paper.citationCount <= max_cita))
          return true
      }
      return false;
    })
    switch (type) {
      case 'none':
          return [...papers]
      case 'year_down':
          return [...papers].sort((a, b) => a.year - b.year)
      case 'year_up':
          return [...papers].sort((a, b) => b.year - a.year)
      case 'citas_down':
          return [...papers].sort((a, b) => a.citationCount - b.citationCount)
      case 'citas_up':
          return [...papers].sort((a, b) => b.citationCount - a.citationCount)
      default:
        return [...papers]
    }
  },
  marks_fechas: state => state.marks_fechas,
  fechas_range: state => state.fechas_range,
  histo_fechas: state => state.histo_fechas,
  marks_citas: state => state.marks_citas,
  citas_range: state => state.citas_range,
  histo_citas: state => state.histo_citas,
  tableFilter: state => state.tableFilter,
  formSearch: state => state.formSearch,
  formFilter: state => state.formFilter,
  query: state => state.formSearch.query,

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  VueToast,
  Vuelidate,
};
