import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import ui from './modules/ui.js'
import vtmar from "./modules/vtmar.js";
import createLogger from 'vuex/dist/logger';
import * as localData from '@/services/localData.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    ui,
    vtmar,
  },
  strict: debug,
  // plugins: debug? [ createLogger() ] : [],
  state: {},
  mutations: {},
  actions: {},
});
